import { useUserContext } from './UserContext';
import {
  routeForLogin,
  routeForHome,
  routeForFinanceOverview,
} from '@watershed/shared-universal/dashboardRoutes';

export function useRootRedirect(): string {
  const userContext = useUserContext();

  if (userContext.hasInitialized === false) {
    return routeForLogin();
  }

  if (userContext.canAccessFinance && !userContext.canAccessCorporate) {
    return routeForFinanceOverview({}).pathname;
  }

  return routeForHome();
}
